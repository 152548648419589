






























































































































































































































































import { Component, Prop, Vue } from "vue-property-decorator";
import { Validator, Rule } from "vee-validate";
import { JWTStore } from "@/store/modules";

Validator.extend("fileUploaded", {
  getMessage: (field: any) => {
    return "At least one image is required";
  },
  validate: (value: any, args: any[]) => {
    if (args.length > 0) {
      // if file added
      if (args[0] && args[0].length > 0) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  }
} as Rule);

Validator.extend("fileType", {
  getMessage: (field: any) => {
    return "The file must be an jpeg or png image.";
  },
  validate: (value: any, args: any[]) => {
    if (args.length > 0) {
      // if file added
      if (args[0] && args[0].length > 0) {
        let imgType = args[0][0].type;
        if (imgType === "image/jpeg" || imgType === "image/png") {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    }
    return false;
  }
} as Rule);
Validator.extend("checkLicenseExists", {
  /** message is taken from traslation files */
  validate: async (value: any, args: any[]) => {
    if (value) {
      await JWTStore.licenseNoExists(value);
      return !JWTStore.LicenseNoExists;
    }
    return false;
  }
} as Rule);

@Component({
  inject: ["$validator"]
})
export default class Registrationthree extends Vue {
  @Prop({ default: {} })
  user!: any;

  seedCompanyLicense: File[] = [] as File[];
  companyRegistrationRenewalCertificate: File[] = [] as File[];
  tax: File[] = [] as File[];
  purchaseProof: File[] = [] as File[];
  pan: File[] = [] as File[];
  chequeBookCover: File[] = [] as File[];
  otherDocument: File[] = [] as File[];

  date: any = "";
  pickdate = {
    disabledDate(time: any) {
      return time.getTime() > Date.now();
    },
    shortcuts: [
      {
        text: "Today",
        onClick(picker: any) {
          picker.$emit("pick", new Date());
        }
      },
      {
        text: "Yesterday",
        onClick(picker: any) {
          const date = new Date();
          date.setTime(date.getTime() - 3600 * 1000 * 24);
          picker.$emit("pick", date);
        }
      },
      {
        text: "A week ago",
        onClick(picker: any) {
          const date = new Date();
          date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
          picker.$emit("pick", date);
        }
      }
    ]
  };

  handleAfterUploadSeedCompanyLicense(file: any, fileList: any[]) {
    this.seedCompanyLicense = [];
    this.seedCompanyLicense.push(file.raw);
    this.$emit("uploadSeedCompanyLicense", file.raw);
  }

  handleAfterUploadCompanyRegistrationRenewalCertificate(file: any, fileList: any[]) {
    this.companyRegistrationRenewalCertificate = [];
    this.companyRegistrationRenewalCertificate.push(file.raw);
    this.$emit("uploadCompanyRegistrationRenewalCertificate", file.raw);
  }

  handleAfterUploadTax(file: any, fileList: any[]) {
    this.tax = [];
    this.tax.push(file.raw);
    this.$emit("uploadTax", file.raw);
  }

  handleAfterUploadPAN(file: any, fileList: any[]) {
    this.pan = [];
    this.pan.push(file.raw);
    this.$emit("uploadPan", file.raw);
  }

  handleAfterUploadParentSeedPurchaseProof(file: any, fileList: any[]) {
    this.purchaseProof = [];
    this.purchaseProof.push(file.raw);
    this.$emit("uploadParentSeedPurchaseProof", file.raw);
  }

  handleAfterUploadChequeBookCover(file: any, fileList: any[]) {
    this.chequeBookCover = [];
    this.chequeBookCover.push(file.raw);
    this.$emit("uploadChequeBookCover", file.raw);
  }

  handleAfterUploadOtherDocument(file: any, fileList: any[]) {
    this.otherDocument = [];
    this.otherDocument.push(file.raw);
    this.$emit("uploadOtherDocument", file.raw);
  }

  checkIfRequired(event: any) {
    let index = this.$validator.fields.items.findIndex(x => x.name == event);
    if (index >= 0 && this.$validator.fields.items[index].flags["required"]) {
      return true;
    }
    return false;
  }
}
