var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-form',{staticClass:"custom-form",attrs:{"label-position":_vm.labelPosition,"label-width":"100px","model":_vm.orgdetails}},[_c('div',{staticClass:"basic_info"},[_c('h5',[_vm._v(_vm._s(_vm.$t("basicInfo")))]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-4 col-md-6 col-sm-12"},[_c('el-form-item',{class:{
            'is-error': _vm.vErrors.has(_vm.companyNepaliNameText),
            'is-required': _vm.checkIfRequired(_vm.companyNepaliNameText)
          },attrs:{"label":_vm.companyNepaliNameText}},[_c('el-input',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"name":_vm.companyNepaliNameText},model:{value:(_vm.user.nepname),callback:function ($$v) {_vm.$set(_vm.user, "nepname", $$v)},expression:"user.nepname"}}),(_vm.vErrors.has(_vm.companyNepaliNameText))?_c('div',{staticClass:"el-form-item__error"},[_vm._v(" "+_vm._s(_vm.vErrors.first(_vm.companyNepaliNameText))+" ")]):_vm._e()],1)],1),_c('div',{staticClass:"col-lg-4 col-md-6 col-sm-12"},[_c('el-form-item',{class:{
            'is-error': _vm.vErrors.has(_vm.companyNameText),
            'is-required': _vm.checkIfRequired(_vm.companyNameText)
          },attrs:{"label":_vm.companyNameText}},[_c('el-input',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"name":_vm.companyNameText},model:{value:(_vm.user.name),callback:function ($$v) {_vm.$set(_vm.user, "name", $$v)},expression:"user.name"}}),(_vm.vErrors.has(_vm.companyNameText))?_c('div',{staticClass:"el-form-item__error"},[_vm._v(" "+_vm._s(_vm.vErrors.first(_vm.companyNameText))+" ")]):_vm._e()],1)],1),_c('div',{staticClass:"col-lg-4 col-md-6 col-sm-12"},[_c('el-form-item',{class:{
            'is-error': _vm.vErrors.has(_vm.companyEmailText),
            'is-required': _vm.checkIfRequired(_vm.companyEmailText)
          },attrs:{"label":_vm.companyEmailText}},[_c('el-input',{directives:[{name:"validate",rawName:"v-validate",value:('required|email|checkUserExists'),expression:"'required|email|checkUserExists'"}],attrs:{"name":_vm.companyEmailText},model:{value:(_vm.user.email),callback:function ($$v) {_vm.$set(_vm.user, "email", $$v)},expression:"user.email"}}),(_vm.vErrors.has(_vm.companyEmailText))?_c('div',{staticClass:"el-form-item__error"},[_vm._v(" "+_vm._s(_vm.vErrors.first(_vm.companyEmailText))+" ")]):_vm._e()],1)],1),_c('div',{staticClass:"col-lg-4 col-md-6 col-sm-12"},[(_vm.user.isc_type != 'farmer')?_c('el-form-item',{class:{
            'is-error': _vm.vErrors.has(_vm.$t('pallikaContactPerson')),
            'is-required': _vm.checkIfRequired(_vm.$t('pallikaContactPerson'))
          },attrs:{"label":_vm.$t('pallikaContactPerson')}},[_c('el-input',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"name":_vm.$t('pallikaContactPerson')},model:{value:(_vm.user.contact_person),callback:function ($$v) {_vm.$set(_vm.user, "contact_person", $$v)},expression:"user.contact_person"}}),(_vm.vErrors.has(_vm.$t('pallikaContactPerson')))?_c('div',{staticClass:"el-form-item__error"},[_vm._v(" "+_vm._s(_vm.vErrors.first(_vm.$t("pallikaContactPerson")))+" ")]):_vm._e()],1):_vm._e()],1),_c('div',{staticClass:"col-lg-4 col-md-6 col-sm-12"},[_c('el-form-item',{class:{
            'is-error': _vm.vErrors.has(_vm.$t('contactNumber')),
            'is-required': _vm.checkIfRequired(_vm.$t('contactNumber'))
          },attrs:{"label":_vm.$t('contactNumber')}},[_c('el-input',{directives:[{name:"validate",rawName:"v-validate",value:('required|numeric'),expression:"'required|numeric'"}],attrs:{"type":"number","name":_vm.$t('contactNumber')},model:{value:(_vm.user.contact_number),callback:function ($$v) {_vm.$set(_vm.user, "contact_number", $$v)},expression:"user.contact_number"}}),(_vm.vErrors.has(_vm.$t('contactNumber')))?_c('div',{staticClass:"el-form-item__error"},[_vm._v(" "+_vm._s(_vm.vErrors.first(_vm.$t("contactNumber")))+" ")]):_vm._e()],1)],1),_c('div',{staticClass:"col-lg-6 col-md-12"},[_c('el-form-item',{class:{
            'is-error': _vm.vErrors.has(_vm.$t('seedConsumerEmailVerification')),
            'is-required': _vm.checkIfRequired(_vm.$t('seedConsumerEmailVerification'))
          },attrs:{"label":((_vm.$t('seedConsumerEmailVerification')) + " (*.pdf)")}},[_c('div',[_c('el-upload',{ref:"upload",staticClass:"upload-demo",attrs:{"file-list":_vm.user.improved_seed_consumer_email_verification,"on-change":_vm.handleAfterUploaduploadImprovedSeedConsumerEmailverification,"limit":1,"list-type":"text","action":"","auto-upload":false,"accept":"application/pdf"}},[_c('el-button',{directives:[{name:"validate",rawName:"v-validate",value:('required|mimes:application/pdf'),expression:"'required|mimes:application/pdf'"}],attrs:{"slot":"trigger","name":_vm.$t('seedConsumerEmailVerification'),"size":"small","type":"primary"},slot:"trigger",model:{value:(_vm.seedConsumerEmailVerification),callback:function ($$v) {_vm.seedConsumerEmailVerification=$$v},expression:"seedConsumerEmailVerification"}},[_vm._v(_vm._s(_vm.$t("selectFile")))])],1)],1),(_vm.vErrors.has(_vm.$t('seedConsumerEmailVerification')))?_c('div',{staticClass:"el-form-item__error"},[_vm._v(" "+_vm._s(_vm.vErrors.first(_vm.$t("seedConsumerEmailVerification")))+" ")]):_vm._e()])],1)])]),_c('div',{staticClass:"address_info"},[_c('h5',[_vm._v(_vm._s(_vm.$t("addressInfo")))]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-4 col-md-6 col-sm-12"},[_c('el-form-item',{class:{
            'is-error': _vm.vErrors.has(_vm.$t('province')),
            'is-required': _vm.checkIfRequired(_vm.$t('province'))
          },attrs:{"label":_vm.$t('province')}},[_c('el-select',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],staticClass:"fullWidth",attrs:{"name":_vm.$t('province'),"filterable":""},model:{value:(_vm.user.provinceid),callback:function ($$v) {_vm.$set(_vm.user, "provinceid", $$v)},expression:"user.provinceid"}},_vm._l((_vm.provinces),function(province){return _c('el-option',{key:province.id,attrs:{"value":province.id,"label":_vm.$i18n.locale == 'ne' && province.nepname ? province.nepname : province.name}})}),1),(_vm.vErrors.has(_vm.$t('province')))?_c('div',{staticClass:"el-form-item__error"},[_vm._v(" "+_vm._s(_vm.vErrors.first(_vm.$t("province")))+" ")]):_vm._e()],1)],1),_c('div',{staticClass:"col-lg-3 col-md-6 col-sm-12"},[_c('el-form-item',{class:{
            'is-error': _vm.vErrors.has(_vm.$t('district')),
            'is-required': _vm.checkIfRequired(_vm.$t('district'))
          },attrs:{"label":_vm.$t('district')}},[_c('el-select',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],staticClass:"fullWidth",attrs:{"name":_vm.$t('district'),"filterable":""},model:{value:(_vm.user.districtid),callback:function ($$v) {_vm.$set(_vm.user, "districtid", $$v)},expression:"user.districtid"}},_vm._l((_vm.districts),function(district){return _c('el-option',{key:district.id,attrs:{"value":district.id,"label":_vm.$i18n.locale == 'ne' && district.nepname ? district.nepname : district.name}})}),1),(_vm.vErrors.has(_vm.$t('district')))?_c('div',{staticClass:"el-form-item__error"},[_vm._v(" "+_vm._s(_vm.vErrors.first(_vm.$t("district")))+" ")]):_vm._e()],1)],1),_c('div',{staticClass:"col-lg-3 col-md-6 col-sm-12"},[_c('el-form-item',{class:{
            'is-error': _vm.vErrors.has(_vm.$t('palika')),
            'is-required': _vm.checkIfRequired(_vm.$t('palika'))
          },attrs:{"label":_vm.$t('palika')}},[_c('el-select',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],staticClass:"fullWidth",attrs:{"name":_vm.$t('palika'),"filterable":""},model:{value:(_vm.user.palikaid),callback:function ($$v) {_vm.$set(_vm.user, "palikaid", $$v)},expression:"user.palikaid"}},_vm._l((_vm.palikas),function(palika){return _c('el-option',{key:palika.id,attrs:{"value":palika.id,"label":((_vm.$i18n.locale == 'ne' && palika.nepname ? palika.nepname : palika.name) + " " + (palika.palika_type_name
                    ? _vm.$i18n.locale == 'ne' && palika.palika_type_nepname
                      ? palika.palika_type_nepname
                      : palika.palika_type_name
                    : ''))}})}),1),(_vm.vErrors.has(_vm.$t('palika')))?_c('div',{staticClass:"el-form-item__error"},[_vm._v(" "+_vm._s(_vm.vErrors.first(_vm.$t("palika")))+" ")]):_vm._e()],1)],1),_c('div',{staticClass:"col-lg-2 col-md-6 col-sm-12"},[_c('el-form-item',{class:{
            'is-error': _vm.vErrors.has(_vm.$t('ward')),
            'is-required': _vm.checkIfRequired(_vm.$t('ward'))
          },attrs:{"label":_vm.$t('ward')}},[_c('el-input',{directives:[{name:"validate",rawName:"v-validate",value:('min_value:1'),expression:"'min_value:1'"}],attrs:{"type":"number","name":_vm.$t('ward')},model:{value:(_vm.user.ward),callback:function ($$v) {_vm.$set(_vm.user, "ward", $$v)},expression:"user.ward"}}),(_vm.vErrors.has(_vm.$t('ward')))?_c('div',{staticClass:"el-form-item__error"},[_vm._v(" "+_vm._s(_vm.vErrors.first(_vm.$t("ward")))+" ")]):_vm._e()],1)],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }