var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-form',{staticClass:"custom-form"},[_c('div',{staticClass:"file_attachments"},[_c('h5',[_vm._v(_vm._s(_vm.$t("attachment")))]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-4 col-md-12"},[_c('el-form-item',{class:{
            'is-error': _vm.vErrors.has(_vm.$t('approvalLetter')),
            'is-required': _vm.checkIfRequired(_vm.$t('approvalLetter'))
          },attrs:{"label":_vm.$t('approvalLetter')}},[_c('el-radio-group',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"name":_vm.$t('approvalLetter')},on:{"change":_vm.changeRadio},model:{value:(_vm.radioModel),callback:function ($$v) {_vm.radioModel=$$v},expression:"radioModel"}},[_c('el-radio',{attrs:{"label":true}},[_vm._v(_vm._s(_vm.$t("has"))+" ")]),_c('el-radio',{attrs:{"label":false}},[_vm._v(_vm._s(_vm.$t("hasNot")))])],1),(_vm.vErrors.has(_vm.$t('approvalLetter')))?_c('div',{staticClass:"el-form-item__error"},[_vm._v(" "+_vm._s(_vm.vErrors.first(_vm.$t("approvalLetter")))+" ")]):_vm._e()],1)],1),(_vm.hasSeedLicense)?_c('div',{staticClass:"col-lg-4 col-md-12"},[_c('el-form-item',{class:{
            'is-error': _vm.vErrors.has(_vm.$t('seedLicenseNo')),
            'is-required': _vm.checkIfRequired(_vm.$t('seedLicenseNo'))
          },attrs:{"label":_vm.$t('seedLicenseNo')}},[_c('el-input',{directives:[{name:"validate",rawName:"v-validate",value:('required|checkLicenseExists'),expression:"'required|checkLicenseExists'"}],key:_vm.$t('seedLicenseNo'),attrs:{"name":_vm.$t('seedLicenseNo'),"placeholder":_vm.$t('licenseNo')},model:{value:(_vm.user.seed_license_no),callback:function ($$v) {_vm.$set(_vm.user, "seed_license_no", $$v)},expression:"user.seed_license_no"}}),(_vm.vErrors.has(_vm.$t('seedLicenseNo')))?_c('div',{staticClass:"el-form-item__error"},[_vm._v(" "+_vm._s(_vm.vErrors.first(_vm.$t("seedLicenseNo")))+" ")]):_vm._e()],1)],1):_vm._e(),(_vm.hasSeedLicense)?_c('div',{staticClass:"col-lg-4 col-md-12"},[_c('el-form-item',{class:{
            'is-error': _vm.vErrors.has(_vm.$t('improvedSeedProductionLicense')),
            'is-required': _vm.checkIfRequired(_vm.$t('improvedSeedProductionLicense'))
          }},[_c('div',[_c('el-upload',{ref:"upload",staticClass:"upload-demo",staticStyle:{"float":"left","margin-top":"30px"},attrs:{"file-list":_vm.user.company_license,"on-change":_vm.handleAfterUploadSeedCompanyLicense,"limit":1,"list-type":"text","action":"","auto-upload":false,"accept":"application/pdf"}},[_c('el-button',{directives:[{name:"validate",rawName:"v-validate",value:('required|mimes:application/pdf'),expression:"'required|mimes:application/pdf'"}],key:_vm.$t('improvedSeedProductionLicense'),attrs:{"slot":"trigger","name":_vm.$t('improvedSeedProductionLicense'),"size":"small","type":"primary"},slot:"trigger",model:{value:(_vm.seedCompanyLicense),callback:function ($$v) {_vm.seedCompanyLicense=$$v},expression:"seedCompanyLicense"}},[_vm._v(_vm._s(_vm.$t("selectFile")))])],1)],1),(_vm.vErrors.has(_vm.$t('improvedSeedProductionLicense')))?_c('div',{staticClass:"el-form-item__error"},[_vm._v(" "+_vm._s(_vm.vErrors.first(_vm.$t("improvedSeedProductionLicense")))+" ")]):_vm._e()])],1):_vm._e(),(_vm.hasSeedLicense == false)?_c('div',{staticClass:"col-lg-5 col-md-12"},[_c('el-form-item',{class:{
            'is-error': _vm.vErrors.has(_vm.$t('districtKnowledgeCenter')),
            'is-required': _vm.checkIfRequired(_vm.$t('districtKnowledgeCenter'))
          },attrs:{"label":((_vm.$t('districtKnowledgeCenter')) + " (*.pdf)")}},[_c('div',[_c('el-upload',{ref:"upload",staticClass:"upload-demo",attrs:{"file-list":_vm.user.company_license,"on-change":_vm.handleAfterUploadSeedCompanyLicense,"limit":1,"list-type":"text","action":"","auto-upload":false,"accept":"application/pdf"}},[_c('el-button',{directives:[{name:"validate",rawName:"v-validate",value:('required|mimes:application/pdf'),expression:"'required|mimes:application/pdf'"}],key:_vm.$t('districtKnowledgeCenter'),attrs:{"slot":"trigger","name":_vm.$t('districtKnowledgeCenter'),"size":"small","type":"primary"},slot:"trigger",model:{value:(_vm.seedCompanyLicense),callback:function ($$v) {_vm.seedCompanyLicense=$$v},expression:"seedCompanyLicense"}},[_vm._v(_vm._s(_vm.$t("selectFile")))])],1)],1),(_vm.vErrors.has(_vm.$t('districtKnowledgeCenter')))?_c('div',{staticClass:"el-form-item__error"},[_vm._v(" "+_vm._s(_vm.vErrors.first(_vm.$t("districtKnowledgeCenter")))+" ")]):_vm._e()])],1):_vm._e(),(_vm.hasSeedLicense == false)?_c('el-form-item',{staticClass:"col-lg-6 col-md-12 col-sm-12",class:{
          'is-error': _vm.vErrors.has(_vm.$t('trainingApplication')),
          'is-required': _vm.checkIfRequired(_vm.$t('trainingApplication')),
          fullWidth: _vm.mobileScreen
        },attrs:{"label":((_vm.$t('trainingApplication')) + " (*.pdf)")}},[_c('div',[_c('el-upload',{ref:"upload",staticClass:"upload-demo",attrs:{"limit":1,"list-type":"text","on-change":_vm.handleAfterUploadTrainingApplication,"action":"","auto-upload":false,"accept":"application/pdf"}},[_c('el-button',{staticClass:"download-form-button",attrs:{"size":"small","type":"success","native-type":"button"},on:{"click":function($event){return _vm.downloadFile(
                  '/static/download_documents/training_application.docx',
                  'training_application.docx'
                )}}},[_vm._v(" "+_vm._s(_vm.$t("downloadForm"))+" ")]),_c('el-button',{directives:[{name:"validate",rawName:"v-validate",value:({
                required: _vm.trainingApplication && _vm.trainingApplication.length < 1,
                mimes: 'application/pdf'
              }),expression:"{\n                required: trainingApplication && trainingApplication.length < 1,\n                mimes: 'application/pdf'\n              }"}],attrs:{"slot":"trigger","name":_vm.$t('trainingApplication'),"size":"small","type":"primary"},slot:"trigger",model:{value:(_vm.trainingApplication),callback:function ($$v) {_vm.trainingApplication=$$v},expression:"trainingApplication"}},[_vm._v(_vm._s(_vm.$t("selectFile")))])],1)],1),(_vm.vErrors.has(_vm.$t('trainingApplication')))?_c('div',{staticClass:"el-form-item__error"},[_vm._v(" "+_vm._s(_vm.vErrors.first(_vm.$t("trainingApplication")))+" ")]):_vm._e()]):_vm._e()],1),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-6 col-md-12"},[_c('el-form-item',{class:{
            'is-error': _vm.vErrors.has(_vm.$t('companyRegistrationRenewalCertificate')),
            'is-required': _vm.checkIfRequired(_vm.$t('companyRegistrationRenewalCertificate'))
          },attrs:{"label":((_vm.$t('companyRegistrationRenewalCertificate')) + " (*.pdf)")}},[_c('div',[_c('el-upload',{ref:"upload",staticClass:"upload-demo",attrs:{"file-list":_vm.user.company_registration_renewal_certificate,"on-change":_vm.handleAfterUploadCompanyRegistrationRenewalCertificate,"limit":1,"list-type":"text","action":"","auto-upload":false,"accept":"application/pdf"}},[_c('el-button',{directives:[{name:"validate",rawName:"v-validate",value:('required|mimes:application/pdf'),expression:"'required|mimes:application/pdf'"}],attrs:{"slot":"trigger","name":_vm.$t('companyRegistrationRenewalCertificate'),"size":"small","type":"primary"},slot:"trigger",model:{value:(_vm.companyRegistrationRenewalCertificate),callback:function ($$v) {_vm.companyRegistrationRenewalCertificate=$$v},expression:"companyRegistrationRenewalCertificate"}},[_vm._v(_vm._s(_vm.$t("selectFile")))])],1)],1),(_vm.vErrors.has(_vm.$t('companyRegistrationRenewalCertificate')))?_c('div',{staticClass:"el-form-item__error"},[_vm._v(" "+_vm._s(_vm.vErrors.first(_vm.$t("companyRegistrationRenewalCertificate")))+" ")]):_vm._e()])],1),_c('div',{staticClass:"col-lg-6 col-md-12"},[_c('el-form-item',{class:{
            'is-error': _vm.vErrors.has(_vm.$t('tax')),
            'is-required': _vm.checkIfRequired(_vm.$t('tax'))
          },attrs:{"label":((_vm.$t('tax')) + " (*.pdf)")}},[_c('div',[_c('el-upload',{ref:"upload",staticClass:"upload-demo",attrs:{"file-list":_vm.user.tax,"on-change":_vm.handleAfterUploadTax,"limit":1,"list-type":"text","action":"","auto-upload":false,"accept":"application/pdf"}},[_c('el-button',{directives:[{name:"validate",rawName:"v-validate",value:('required|mimes:application/pdf'),expression:"'required|mimes:application/pdf'"}],attrs:{"slot":"trigger","name":_vm.$t('tax'),"size":"small","type":"primary"},slot:"trigger",model:{value:(_vm.tax),callback:function ($$v) {_vm.tax=$$v},expression:"tax"}},[_vm._v(_vm._s(_vm.$t("selectFile")))])],1)],1),(_vm.vErrors.has(_vm.$t('tax')))?_c('div',{staticClass:"el-form-item__error"},[_vm._v(" "+_vm._s(_vm.vErrors.first(_vm.$t("tax")))+" ")]):_vm._e()])],1),_c('div',{staticClass:"col-lg-6 col-md-12"},[_c('el-form-item',{class:{
            'is-error': _vm.vErrors.has(_vm.$t('PAN')),
            'is-required': _vm.checkIfRequired(_vm.$t('PAN'))
          },attrs:{"label":((_vm.$t('PAN')) + " (*.pdf)")}},[_c('div',[_c('el-upload',{ref:"upload",staticClass:"upload-demo",attrs:{"file-list":_vm.user.pan,"on-change":_vm.handleAfterUploadPAN,"limit":1,"list-type":"text","action":"","auto-upload":false,"accept":"application/pdf"}},[_c('el-button',{directives:[{name:"validate",rawName:"v-validate",value:('required|mimes:application/pdf'),expression:"'required|mimes:application/pdf'"}],attrs:{"slot":"trigger","name":_vm.$t('PAN'),"size":"small","type":"primary"},slot:"trigger",model:{value:(_vm.pan),callback:function ($$v) {_vm.pan=$$v},expression:"pan"}},[_vm._v(_vm._s(_vm.$t("selectFile")))])],1)],1),(_vm.vErrors.has(_vm.$t('PAN')))?_c('div',{staticClass:"el-form-item__error"},[_vm._v(" "+_vm._s(_vm.vErrors.first(_vm.$t("PAN")))+" ")]):_vm._e()])],1),_c('div',{staticClass:"col-lg-6 col-md-12"},[_c('el-form-item',{class:{
            'is-error': _vm.vErrors.has(_vm.$t('otherDocument')),
            'is-required': _vm.checkIfRequired(_vm.$t('otherDocument'))
          },attrs:{"label":((_vm.$t('otherDocument')) + " (*.pdf)")}},[_c('div',[_c('el-upload',{ref:"upload",staticClass:"upload-demo",attrs:{"file-list":_vm.user.otherdocument,"on-change":_vm.handleAfterUploadOtherDocument,"limit":1,"list-type":"text","action":"","auto-upload":false,"accept":"application/pdf"}},[_c('el-button',{directives:[{name:"validate",rawName:"v-validate",value:('mimes:application/pdf'),expression:"'mimes:application/pdf'"}],attrs:{"slot":"trigger","name":_vm.$t('otherDocument'),"size":"small","type":"primary"},slot:"trigger",model:{value:(_vm.otherDocument),callback:function ($$v) {_vm.otherDocument=$$v},expression:"otherDocument"}},[_vm._v(_vm._s(_vm.$t("selectFile")))])],1)],1),(_vm.vErrors.has(_vm.$t('otherDocument')))?_c('div',{staticClass:"el-form-item__error"},[_vm._v(" "+_vm._s(_vm.vErrors.first(_vm.$t("otherDocument")))+" ")]):_vm._e()])],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }