











































import { Component, Vue, Prop } from "vue-property-decorator";

@Component
export default class ChooseISMType extends Vue {
  @Prop({ default: {} })
  user!: any;
}
