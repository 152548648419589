





















































































































































































































































































































import { Component, Prop, Vue } from "vue-property-decorator";
import { CompanyUser } from "@/store/models/user";
import { MetaStore, JWTStore } from "@/store/modules";

@Component({
  inject: ["$validator"]
})
export default class Registrationtwo extends Vue {
  @Prop({ default: {} })
  user!: any;
  option: any = "";

  labelPosition: string = "top";
  userexists: boolean = false;
  loading: boolean = false;

  organization = [
    {
      label: "Government",
      value: "Government",
      description: ""
    },
    { label: "Private", value: "Private", description: "" },
    { label: "NGO/INGO", value: "NGO/INGO", description: "" },
    {
      label: "Research Center",
      value: "Research Center",
      description: ""
    },
    { label: "Group/Cooperative", value: "Cooperative", description: "" },
    { label: "Others", value: "Others", description: "" }
  ];
  orgdetails = {
    name: "",
    region: "",
    type: "",
    password: ""
  };

  improved_seed_application: File[] = [] as File[];
  seedConsumerEmailVerification: File[] = [] as File[];

  get companyNameText() {
    if (this.user.isc_type == "palika") {
      return `${this.$t("palika")}${this.$t("'s")} ${this.$t("name")}(अंग्रेजी)`;
    } else if (this.user.isc_type == "cooperative") {
      return `${this.$t("cooperative")}${this.$t("'s")} ${this.$t("name")}(अंग्रेजी)`;
    } else if (this.user.isc_type == "group") {
      return `${this.$t("group")}${this.$t("'s")} ${this.$t("name")}(अंग्रेजी)`;
    } else if (this.user.isc_type == "farmer") {
      return `${this.$t("name")}(अंग्रेजी)`;
    }
    return this.$t("companyName");
  }

  get companyNepaliNameText() {
    if (this.user.isc_type == "palika") {
      return `${this.$t("palika")}${this.$t("'s")} ${this.$t("name")} ${this.$t("inDevnagari")}`;
    } else if (this.user.isc_type == "cooperative") {
      return `${this.$t("cooperative")}${this.$t("'s")} ${this.$t("name")} ${this.$t(
        "inDevnagari"
      )}`;
    } else if (this.user.isc_type == "group") {
      return `${this.$t("group")}${this.$t("'s")} ${this.$t("name")} ${this.$t("inDevnagari")}`;
    } else if (this.user.isc_type == "farmer") {
      return `${this.$t("name")} ${this.$t("inDevnagari")}`;
    }

    return this.$t("companyNepaliName");
  }

  get companyEmailText() {
    if (this.user.isc_type == "palika") {
      return `${this.$t("palika")}${this.$t("'s")} ${this.$t("emailUsername")}`;
    } else if (this.user.isc_type == "cooperative") {
      return `${this.$t("cooperative")}${this.$t("'s")} ${this.$t("emailUsername")}`;
    } else if (this.user.isc_type == "group") {
      return `${this.$t("group")}${this.$t("'s")} ${this.$t("emailUsername")}`;
    } else if (this.user.isc_type == "farmer") {
      return `${this.$t("emailUsername")}`;
    }

    return this.$t("companyEmail");
  }

  get provinces() {
    return MetaStore.Province;
  }

  handleAfterUploaduploadImprovedSeedConsumerEmailverification(file: any, fileList: any[]) {
    this.seedConsumerEmailVerification = [];
    this.seedConsumerEmailVerification.push(file.raw);
    this.$emit("uploadImprovedSeedConsumerEmailverification", file.raw);
  }

  handleAfterUploadImprovedSeedApplication(file: any) {
    this.improved_seed_application = [];
    this.improved_seed_application.push(file.raw);
    this.$emit("uploadImprovedSeedApplication", file.raw);
  }

  get districts() {
    let district = MetaStore.District;
    if (this.user.provinceid) {
      district = district.filter(x => x.provinceid == this.user.provinceid);
    } else {
      district = [];
    }
    return district;
  }

  get palikas() {
    let palika = MetaStore.Palika;
    if (this.user.districtid) {
      palika = palika.filter(x => x.districtid == this.user.districtid);
    } else {
      palika = [];
    }
    return palika;
  }

  get banklist() {
    return MetaStore.BankList;
  }

  async checkemailexists() {
    this.loading = true;
    await JWTStore.userexists(this.user.email);
    this.userexists = JWTStore.UserExists;
    this.loading = false;
  }

  checkIfRequired(event: any) {
    let index = this.$validator.fields.items.findIndex(x => x.name == event);
    if (index >= 0 && this.$validator.fields.items[index].flags["required"]) {
      return true;
    }
    return false;
  }

  created() {
    MetaStore.getAllProvince();
    MetaStore.getAllDistrict();
    MetaStore.getAllPalika();
    MetaStore.getAllBankList();
  }
}
